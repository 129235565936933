import styled from '@emotion/styled'
import { responsiveColumn } from 'src/themes'

const CourseSummary = styled.div`
	display: flex;
	width: 100%;
	${responsiveColumn};

	> ul {
		flex: 1;
		list-style: none;
		padding: 0;

		> li {
			padding-left: 3rem;
			display: flex;
			flex-direction: column;
			position: relative;
			margin-bottom: 1rem;

			> em {
				font-size: 0.8rem;
			}

			> svg {
				position: absolute;
				top: 0.5rem;
				left: 0;
				width: 2rem;
				height: 2rem;
			}
		}
	}
`

export default CourseSummary
