import CourseNav from '@components/CourseNav'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Course } from './_layout'

const CoursePRONav = ({ course }: { course: Course }) => {
	const { t } = useTranslation()

	return (
		<CourseNav
			links={[
				{
					path: `/courses/${course.code}/`,
					label: t('courses:common.overview'),
				},
				{
					path: `/courses/${course.code}/details/`,
					label: t('courses:common.curriculum'),
				},
				{
					path: `/courses/${course.code}/instructors/`,
					label: t('courses:common.instructor_team'),
				},
			]}
		/>
	)
}

export default CoursePRONav
